.formCard {
  padding: 20px;
  h3 {
    margin-bottom: 20px;
  }
}

.field {
  margin-bottom: 30px;
}

.fieldCustomWidth {
  max-width: 431px;
}

.checkboxGroup {
  margin-top: 30px;

  label {
    margin-bottom: 10px;
  }
}

.enableModalCheckbox {
  margin-bottom: 8px;
}

.bodyText {
  height: 108px;
}
