@use "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@use "@dealroadshow/uikit/core/styles/variables/_icons.scss";

.iconWrapper {
  padding: 41px 44px;
  background-color: colors.$secondary5Color;
}

.fileName {
  margin-left: 20px;
  max-width: 150px;
  text-align: left;
  color: colors.$titleColor;
}

.text {
  color: colors.$secondary3Color;
  position: absolute;
  top: calc(50% - 18px);
  right: calc(50% - 95px);
}

.tip {
  color: colors.$secondary2Color;
}

.icon {
  width: icons.$iconL;
  height: icons.$iconL;
  fill: colors.$secondaryColor;
}
