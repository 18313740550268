@use "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.panel {
  background: #fff;
  padding: 20px;
  margin-bottom: 20px;
}

.formField {
  width: 100%;
  flex: 1;
  max-width: 378px;
}

.firstFormField {
  margin-top: 30px;
}

.textWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
}

.dropzone {
  display: flex;
  align-items: center;
  height: auto;
  padding: 0;
}

.maxSizeTip {
  text-align: center;
  font-size: 11px;
  color: colors.$secondaryColor;
  margin-top: 10px;
  line-height: 18px;
}

.formCard {
  padding: 20px;
}

.formHeader {
  display: flex;
  justify-content: space-between;
}

.exportBtn {
  height: 28px;
  line-height: 28px;
  font-weight: 400;

  &[disabled] {
    border: colors.$secondaryColor 1px solid;
    height: 28px;
    line-height: 28px;
    font-weight: 400;

    i {
      fill: colors.$secondaryColor;
    }

    &:hover {
      i {
        fill: colors.$secondaryColor;
      }
    }
  }

  i {
    fill: colors.$actionColor;
    width: 12px;
    height: 12px;
    margin-right: 10px;
    top: 1px;
  }

  &:hover {
    i {
      fill: colors.$actionColorHover;
    }
  }
}
