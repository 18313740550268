@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/card.scss";

.tableFilters {
  position: absolute;
  right: 10px;
  top: 20px;
  display: flex;

  @include mqMaxWidth($screenL) {
    right: auto;
    left: 20px;
  }
}

.headline {
  white-space: nowrap;
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.actionWrapper {
  margin-right: 20px;
}


.emailHistory {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  line-height: 17px;
  margin-top: 20px;
}

.emailHistoryRow {
  display: flex;

  + .emailHistoryRow {
    margin-top: 10px;
  }
}

.emailHistoryRowTitle {
  margin: 0 10px 0 0;
  color: $ColorGrayscaleGray3;
}

.emailHistoryRowDate {
  margin: 0;
}

